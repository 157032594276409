import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import {
  EffectComposer,
  EffectPass,
  RenderPass,
  BloomEffect,
} from "postprocessing";

let composer;
let mesh;
let renderer;
let scene;
let camera;

const container = document.querySelector(".canvas-container");
window.addEventListener("resize", onWindowResize, false);

init();
animate();

function init() {
  camera = new THREE.PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    1,
    1000
  );

  camera.position.z = 2;
  scene = new THREE.Scene();

  const pointLight1 = new THREE.PointLight(0xffffff, 0.55);
  pointLight1.position.set(0, 300, 500);

  const pointLight2 = new THREE.PointLight(0xffffff, 0.75);
  pointLight2.position.set(500, 100, 0);

  const pointLight3 = new THREE.PointLight(0xffffff, 0.75);
  pointLight3.position.set(-500, 300, 0);

  const pointLight4 = new THREE.PointLight(0xffffff, 0.55);
  pointLight4.position.set(0, -300, 100);

  scene.add(pointLight1);
  scene.add(pointLight2);
  scene.add(pointLight3);
  scene.add(pointLight4);

  const loader = new GLTFLoader();

  loader.load(
    "assets/gm.gltf",
    function (gltf) {
      const root = gltf.scene;

      // make the model huge
      root.scale.multiplyScalar(1.5);

      const matOptions = {
        roughness: 0.3,
        metalness: 0,
        reflectivity: 1,
        color: 0xffcf5f,
      };

      const mat = new THREE.MeshPhysicalMaterial(matOptions);
      root.traverse((o) => {
        if (o.isMesh) {
          o.material = mat;
          mesh = o;
          mesh.position.y += isMob() ? 0.075 : 0.025;
          mesh.scale.set(75, 75, 75);
          mesh.matrixAutoUpdate = true;
        }
      });

      scene.add(root);
    },
    undefined,
    function (error) {
      console.error(error);
    }
  );

  renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true });
  renderer.setSize(container.offsetWidth, container.offsetHeight);
  renderer.setPixelRatio(0.5);

  // post effects
  composer = new EffectComposer(renderer);
  composer.addPass(new RenderPass(scene, camera));

  const effectPass = new EffectPass(camera, new BloomEffect());
  effectPass.renderToScreen = true;
  composer.addPass(effectPass);

  container.appendChild(renderer.domElement);

  // adjust aspect ratio for half page
  camera.aspect = container.offsetWidth / container.offsetHeight;
  camera.updateProjectionMatrix();

  setTimeout(() => {
    const g = document.querySelector(".g");
    g.style.alignItems = "center";
    g.style.justifyItems = "center";
    g.querySelector(".nf").style.opacity = 1;
  }, 100);
}

function isMob() {
  return window.innerWidth <= 800;
}

function animate() {
  requestAnimationFrame(animate);

  if (mesh) {
    mesh.rotation.z += 0.015;
  }

  composer.render();
}

function onWindowResize() {
  camera.aspect = container.offsetWidth / container.offsetHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(container.offsetWidth, container.offsetHeight);
}
